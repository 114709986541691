<template>
    <div class="shop_page_footer">
        <div class="shop_page_content">
            <ul>
                <li><a href="javascript:void(0)" @click="defaultActive === '/index' ? '' : $router.push('/index')">首页</a></li>
                <li>|</li>
                <li><a href="javascript:void(0)" @click="defaultActive === '/shop/help' ? '' : $router.push('/shop/help')">帮助中心</a></li>
                <li>|</li>
                <li><a href="javascript:void(0)" @click="defaultActive === '/shop/news' ? '' : $router.push('/shop/news')">新闻资讯</a></li>
                <li>|</li>
                <li><a href="javascript:void(0)" @click="defaultActive === '/shop/cooperate' ? '' : $router.push('/shop/cooperate')">商务合作</a></li>
                <li>|</li>
                <li><a href="javascript:void(0)" @click="defaultActive === '/shop/about' ? '' : $router.push('/shop/about')">关于我们</a></li>
            </ul>

            <div class="bot_text">
                <div class="item">
                    <span>互联网药品信息服务资格证：(辽)-经营性-2021-0018 | 增值电信业务经营许可证编号：辽 B2-20210244</span>
                </div>
                <div class="item">
                    <a target="_blank" href="https://beian.miit.gov.cn/">辽 ICP 备 2021001284 号</a>
                    <span> | </span>
                    <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=21010402000377">
                        <img src="../../../assets/img/footer_icon.png" style="width: 16px; height: 16px" />
                        <span> 辽公网安备 21010402000377 号</span>
                    </a>
                    <span> | </span>
                    <a target="_blank" href="https://zzlz.gsxt.gov.cn/businessCheck/verifKey.do?showType=p&serial=91211223MA10RPCQ3D-SAIC_SHOW_10000091211223MA10RPCQ3D1626843017056&signData=MEQCIJG7ONm0ir0rYVSiXoESk5nuVPtnSVfVAcVsk9PMFVNuAiCTkrVV42txDDerNGBhu7wNTZuCXc6T1ILp2g+QzJh1nw==">
                        <img src="../../../assets/img/footer_icon01.png" style="width: 22px; height: 22px" />
                        <span> 电子营业执照</span>
                    </a>
                </div>
                <div class="item">
                    <span>Copyright © 2020-2036 易诚众联网络科技有限公司版权所有</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import {mapState} from "vuex";

  export default {
    name: "LayoutFooter",
    computed: {
      ...mapState({
        defaultActive: state => state.user.defaultActive,
      })
    }
  }
</script>
